/* Main container */
.sharedDocumentContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8fafc;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-top: 64px; /* Space for the fixed navbar */
  color: #1e293b;
  --primary-color: #0f172a;
  --primary-hover: #1e293b;
  --accent-color: #3b82f6;
  --accent-hover: #2563eb;
  --text-primary: #0f172a;
  --text-secondary: #334155;
  --text-tertiary: #64748b;
  --border-color: #e2e8f0;
  --background-light: #f8fafc;
  --background-white: #ffffff;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.03), 0 1px 6px rgba(0, 0, 0, 0.02);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.04),
    0 2px 4px -1px rgba(0, 0, 0, 0.03);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.04),
    0 4px 6px -2px rgba(0, 0, 0, 0.02);
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --transition-fast: 0.15s ease;
  --transition-normal: 0.25s ease;
  letter-spacing: -0.011em;
}

/* Navbar styles */
.sharedDocumentNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: saturate(180%) blur(8px);
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  z-index: 1000;
  transition: var(--transition-normal);
  box-shadow: var(--shadow-sm);
  border-bottom: 1px solid var(--border-color);
}

.sharedDocumentNavbar.scrolled {
  box-shadow: var(--shadow-md);
  height: 56px;
}

.sharedDocumentNavbarContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sharedDocumentNavbarLeft {
  display: flex;
  align-items: center;
}

.sharedDocumentLogo {
  cursor: pointer;
  transition: transform var(--transition-fast);
}

.sharedDocumentLogo:hover {
  transform: translateY(-1px);
}

.lawpilotLogo {
  font-weight: 700;
  font-size: 16px;
  color: var(--primary-color);
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  gap: 6px;
}

/* .lawpilotLogo::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-color: var(--accent-color);
  border-radius: 4px;
} */

.sharedDocumentNavbarDivider {
  width: 1px;
  height: 20px;
  background-color: var(--border-color);
  margin: 0 16px;
}

.sharedDocumentNavbarTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

/* Document content */
.sharedDocumentContent {
  flex: 1;
  padding: 40px 24px;
  display: flex;
  justify-content: center;
}

.sharedDocumentPaper {
  background-color: var(--background-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  padding: 48px 56px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  position: relative;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23334155' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E");
}

/* Table of Contents Styles */
.tocToggleButton {
  position: fixed;
  top: 80px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: var(--background-white);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: 8px 12px;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  box-shadow: var(--shadow-sm);
  z-index: 10;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.tocToggleButton:hover {
  background-color: var(--background-light);
  color: var(--primary-color);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.tocToggleButton svg {
  font-size: 16px;
  color: var(--primary-color);
}

.tableOfContents {
  position: fixed;
  top: 120px;
  right: 20px;
  width: 280px;
  max-width: 280px;
  max-height: calc(100vh - 180px);
  background-color: var(--background-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--border-color);
  z-index: 100;
  overflow: hidden;
  animation: fadeIn 0.2s ease;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tocHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid var(--border-color);
  background: linear-gradient(
    to right,
    rgba(15, 23, 42, 0.03),
    rgba(51, 65, 85, 0.03)
  );
}

.tocHeader h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary);
}

.tocCloseButton {
  background: transparent;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: all var(--transition-fast);
}

.tocCloseButton:hover {
  color: var(--text-secondary);
  background-color: rgba(0, 0, 0, 0.05);
}

.tocList {
  list-style: none;
  padding: 8px 0;
  margin: 0;
  overflow-y: auto;
  flex: 1;
  max-height: calc(
    100vh - 240px
  ); /* Ensure there's a max height for scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--text-tertiary) transparent; /* For Firefox */
}

/* Scrollbar styling for WebKit browsers */
.tocList::-webkit-scrollbar {
  width: 6px;
}

.tocList::-webkit-scrollbar-track {
  background: transparent;
}

.tocList::-webkit-scrollbar-thumb {
  background-color: var(--text-tertiary);
  border-radius: 3px;
}

.tocItem {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all var(--transition-fast);
  font-size: 14px;
  color: var(--text-secondary);
}

.tocItem:hover {
  background-color: rgba(59, 130, 246, 0.05);
  color: var(--accent-color);
}

.tocLevel1 {
  font-weight: 600;
  color: var(--text-primary);
}

.tocLevel2 {
  padding-left: 32px;
  font-weight: 400;
}

.tocItemIcon {
  font-size: 14px;
  color: var(--accent-color);
  opacity: 0.7;
}

.sharedDocumentPaper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, var(--primary-color), var(--accent-color));
  border-top-left-radius: var(--radius-lg);
  border-top-right-radius: var(--radius-lg);
}

.sharedDocumentBody {
  position: relative;
}

.sharedDocumentHeading {
  font-size: 28px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 28px;
  line-height: 1.3;
  position: relative;
  padding-bottom: 14px;
  letter-spacing: -0.02em;
}

.sharedDocumentHeading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 48px;
  height: 2px;
  background: linear-gradient(90deg, var(--primary-color), var(--accent-color));
  border-radius: 2px;
}

.sharedDocumentSubHeading {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 36px;
  margin-bottom: 18px;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: -0.015em;
}

.sharedDocumentSubHeading:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 32px;
  height: 2px;
  background-color: var(--accent-color);
  opacity: 0.2;
  border-radius: 1px;
}

.sharedDocumentParagraph {
  font-size: 16px;
  line-height: 1.7;
  color: var(--text-secondary);
  margin-bottom: 18px;
  white-space: pre-line;
}

/* Markdown styles */
.sharedDocumentParagraph strong,
.sharedDocumentHeading strong,
.sharedDocumentSubHeading strong {
  font-weight: 700;
  color: var(--text-primary);
}

.sharedDocumentParagraph em,
.sharedDocumentHeading em,
.sharedDocumentSubHeading em {
  font-style: italic;
}

.sharedDocumentParagraph u,
.sharedDocumentHeading u,
.sharedDocumentSubHeading u {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.sharedDocumentOrderedList,
.sharedDocumentUnorderedList {
  margin: 0 0 18px 24px;
  padding-left: 16px;
}

.sharedDocumentOrderedList {
  list-style-type: decimal;
}

.sharedDocumentUnorderedList {
  list-style-type: disc;
}

.sharedDocumentOrderedList.level-1,
.sharedDocumentUnorderedList.level-1 {
  margin-left: 24px;
}

.sharedDocumentOrderedList.level-2,
.sharedDocumentUnorderedList.level-2 {
  margin-left: 16px;
}

.sharedDocumentListItem {
  margin-bottom: 8px;
  color: var(--text-secondary);
}

.sharedDocumentBlockquote {
  margin: 18px 0;
  padding: 12px 20px;
  border-left: 4px solid var(--accent-color);
  background-color: rgba(59, 130, 246, 0.05);
  color: var(--text-secondary);
  font-style: italic;
  border-radius: var(--radius-sm);
}

.sharedDocumentCode {
  font-family: monospace;
  background-color: rgba(15, 23, 42, 0.05);
  padding: 16px;
  border-radius: var(--radius-md);
  overflow-x: auto;
  display: block;
  margin: 18px 0;
  color: var(--text-primary);
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.sharedDocumentInlineCode {
  font-family: monospace;
  background-color: rgba(15, 23, 42, 0.05);
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.9em;
  color: var(--text-primary);
}

.sharedDocumentLink {
  color: var(--accent-color);
  text-decoration: none;
  border-bottom: 1px solid rgba(59, 130, 246, 0.3);
  transition: border-color 0.2s ease;
}

.sharedDocumentLink:hover {
  border-bottom-color: var(--accent-color);
}

/* Footer */
.sharedDocumentFooter {
  padding: 20px 24px;
  background-color: var(--background-white);
  box-shadow: 0 -1px 0 var(--border-color);
}

.sharedDocumentFooterContent {
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.sharedDocumentWatermark {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  color: var(--text-tertiary);
  font-size: 13px;
  font-weight: 400;
}

.createdWithText {
  color: var(--text-tertiary);
}

.brandName {
  color: var(--accent-color);
  font-weight: 700;
  position: relative;
  padding: 0 2px;
}

.professionalDocText {
  font-size: 11px;
  background-color: rgba(59, 130, 246, 0.08);
  color: var(--accent-color);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
  margin-left: 4px;
}

.sharedDocumentQualityBadge {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f0fdf4;
  color: #16a34a;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(22, 163, 74, 0.2);
}

/* Loading container */
.sharedDocumentLoadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--background-light);
}

/* Error container */
.sharedDraftErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
  background-color: var(--background-light);
}

.sharedDraftErrorContent {
  background-color: var(--background-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  padding: 40px;
  max-width: 400px;
  width: 100%;
  border: 1px solid var(--border-color);
}

.sharedDraftErrorIcon {
  font-size: 40px;
  margin-bottom: 16px;
}

.sharedDraftErrorContainer h2 {
  font-size: 20px;
  margin-bottom: 8px;
  color: var(--text-primary);
}

.sharedDraftErrorContainer p {
  font-size: 15px;
  color: var(--text-secondary);
  margin-bottom: 24px;
}

.primaryButton {
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.primaryButton:hover {
  background-color: var(--accent-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Mini Signup Button */
.miniSignupButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--accent-color);
  color: white;
  padding: 10px 16px;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.25);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  font-size: 14px;
  font-weight: 500;
  border: none;
  animation: slideInFromLeft 0.3s ease;
}

.miniSignupButton:hover {
  background-color: var(--accent-hover);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(59, 130, 246, 0.3);
}

.miniSignupIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniSignupText {
  white-space: nowrap;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Signup Alert Styles */
.signupAlertViewport {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 10px;
  width: 320px;
  max-width: calc(100vw - 40px);
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.signupAlert {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--border-color);
  overflow: hidden;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.signupAlert[data-state="open"] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.signupAlert[data-state="closed"] {
  animation: slideOut 100ms ease-in;
}

@keyframes slideIn {
  from {
    transform: translateY(calc(100% + 25px));
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(calc(100% + 25px));
    opacity: 0;
  }
}

.signupAlertContent {
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
  text-align: center;
}

.signupAlertIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: rgba(59, 130, 246, 0.1);
  color: var(--accent-color);
  border-radius: 50%;
  margin-bottom: 16px;
  box-shadow: 0 2px 6px rgba(59, 130, 246, 0.15);
}

.signupAlertTextContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.signupAlertTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.signupAlertDescription {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
  line-height: 1.5;
}

.signupAlertActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.signupAlertButton {
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(59, 130, 246, 0.2);
  width: 88%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.signupAlertButton:hover {
  background-color: var(--accent-hover);
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(59, 130, 246, 0.25);
}

.signupAlertSecondaryButton {
  background-color: transparent;
  color: var(--text-secondary);
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 88%;
}

.signupAlertSecondaryButton:hover {
  background-color: rgba(0, 0, 0, 0.03);
  color: var(--text-primary);
}

.signupAlertClose {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-size: 18px;
}

.signupAlertClose:hover {
  color: var(--text-secondary);
  background-color: rgba(0, 0, 0, 0.05);
}

/* Additional spacing for markdown elements in the shared view */
.sharedDocumentBody .actualDocumentMarkdown {
  /* Add some breathing room */
  padding: 10px 0;
}

/* Highlight effect for section when clicked in TOC */
.highlight-section {
  animation: highlight-animation 2s ease;
  scroll-margin-top: 100px; /* Add space at the top when scrolling to an element */
}

@keyframes highlight-animation {
  0% {
    background-color: rgba(59, 130, 246, 0.2);
  }
  70% {
    background-color: rgba(59, 130, 246, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

.sharedDocumentBody .actualDocumentMarkdown h1 {
  margin-top: 40px;
  margin-bottom: 30px;
}

.sharedDocumentBody .actualDocumentMarkdown h2 {
  margin-top: 36px;
  margin-bottom: 24px;
}

.sharedDocumentBody .actualDocumentMarkdown p {
  margin-bottom: 24px;
  line-height: 1.8;
}

.sharedDocumentBody .actualDocumentMarkdown ul,
.sharedDocumentBody .actualDocumentMarkdown ol {
  margin-top: 16px;
  margin-bottom: 24px;
}

.sharedDocumentBody .actualDocumentMarkdown li {
  margin-bottom: 12px;
}

.sharedDocumentBody .actualDocumentMarkdown blockquote {
  margin: 28px 0;
  padding: 16px 24px;
}

/* Add more space between sections */
.sharedDocumentBody .actualDocumentMarkdown > * + * {
  margin-top: 8px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sharedDocumentContainer {
    padding-top: 56px;
  }

  .sharedDocumentNavbar {
    height: 56px;
  }

  .sharedDocumentNavbarTitle {
    font-size: 13px;
    max-width: 200px;
  }

  .sharedDocumentContent {
    padding: 20px 16px;
  }

  .sharedDocumentPaper {
    padding: 32px 20px;
    border-radius: var(--radius-md);
  }

  .sharedDocumentHeading {
    font-size: 22px;
  }

  .sharedDocumentSubHeading {
    font-size: 18px;
  }

  .sharedDocumentParagraph {
    font-size: 15px;
  }

  .sharedDocumentFooter {
    padding: 16px 12px;
  }

  .sharedDocumentFooterContent {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .sharedDocumentQualityBadge {
    font-size: 11px;
    padding: 5px 8px;
  }

  .sharedDraftErrorContainer h2 {
    font-size: 18px;
  }

  .sharedDraftErrorContainer p {
    font-size: 14px;
  }

  .signupAlertViewport {
    bottom: 10px;
    left: 10px;
    width: calc(100vw - 20px);
  }

  /* Table of Contents responsive styles */
  .tocToggleButton {
    top: 12px;
    right: 12px;
    padding: 6px 10px;
    font-size: 12px;
  }

  .tocToggleButton svg {
    font-size: 14px;
  }

  .tableOfContents {
    position: fixed;
    top: 120px;
    right: 20px;
    width: 280px;
    max-width: 280px;
    max-height: calc(100vh - 180px);
    background-color: var(--background-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-lg);
    border: 1px solid var(--border-color);
    z-index: 100;
    overflow: hidden;
    animation: fadeIn 0.2s ease;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    overflow: hidden; /* Ensure content doesn't overflow */
  }

  /* No responsive markdown styles needed here as they're inherited from the main styles */
}
