/* Onboarding Modal Styles */
.appOnboardingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.appOnboardingModalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1;
}

.appOnboardingModalContent {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  width: 500px;
  max-width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 2;
  position: relative;
  transition: all 0.2s ease;
}

.appOnboardingModalContent:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.appOnboardingModalContentSuccess {
  width: 550px;
}

.appOnboardingModalContentInitial {
  width: 600px !important;
  max-width: 95% !important;
}

.appOnboardingContent {
  padding: 0px;
}

/* Header Styles */
.appOnboardingHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  padding: 12px;
  border-radius: 6px;
}

.appOnboardingLogo {
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.appOnboardingHeaderText {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: #191f2f;
  letter-spacing: -0.3px;
}

.appOnboardingHeaderDescription {
  font-size: 14px;
  color: #5a6072;
  margin: 0;
  line-height: 1.4;
  max-width: 95%;
}

.appOnboardingDivider {
  width: 100%;
  height: 1px;
  background-color: #f0f0f5;
  margin: 16px 0;
}

/* Form Styles */
.appOnboardingMain {
  display: flex;
  flex-direction: column;
}

.stepLabel {
  font-size: 12px;
  color: #1252f3;
  margin: 0 0 18px 0;
  font-weight: 600;
  display: inline-block;
  padding: 5px 10px;
  background-color: rgba(18, 82, 243, 0.08);
  border-radius: 4px;
  width: fit-content;
}

.appOnboardingInputContainer {
  margin-bottom: 18px;
  width: 100%;
}

.label {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #191f2f;
}

/* Account Type Selector */
.accountTypeSelector {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.accountTypeOption {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #e1e4e8;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.accountTypeOption:hover {
  border-color: #1252f3;
  background-color: #f0f7ff;
  box-shadow: 0 2px 6px rgba(18, 82, 243, 0.08);
}

.accountTypeSelected {
  border-color: #1252f3;
  background-color: #f0f7ff;
  box-shadow: 0 2px 6px rgba(18, 82, 243, 0.08);
}

.accountTypeIcon {
  color: #1252f3;
  margin-right: 12px;
  flex-shrink: 0;
}

.accountTypeText {
  flex: 1;
}

.accountTypeText h3 {
  margin: 0 0 3px 0;
  font-size: 14px;
  font-weight: 600;
  color: #191f2f;
}

.accountTypeText p {
  margin: 0;
  font-size: 12px;
  color: #5a6072;
}

.accountTypeCheck {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #1252f3;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.input:focus {
  outline: none;
  border-color: #1252f3;
  box-shadow: 0 0 0 2px rgba(18, 82, 243, 0.08);
}

.textarea {
  min-height: 100px;
  resize: vertical;
}

.input-helper-text {
  font-size: 12px;
  margin-top: 6px;
  color: #1252f3;
  display: flex;
  align-items: center;
}

.input-helper-text::before {
  content: "✓";
  margin-right: 4px;
}

select.input {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
}

/* Button Styles */
.appOnboardingMainButtons {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.onboardingNextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1252f3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(18, 82, 243, 0.15);
}

.onboardingNextButton:hover {
  background-color: #0a3fd0;
  box-shadow: 0 3px 8px rgba(18, 82, 243, 0.2);
}

.onboardingNextButton:active {
  transform: translateY(1px);
}

.onboardingButtonText {
  margin: 0;
}

.appOnboardingBackButton {
  margin-left: 16px;
  color: #5a6072;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 6px 10px;
  border-radius: 4px;
}

.appOnboardingBackButton:hover {
  color: #191f2f;
  background-color: #f5f5f7;
}

.disabledButton {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabledButton:hover {
  background-color: #1252f3;
  transform: none;
  box-shadow: 0 2px 6px rgba(18, 82, 243, 0.15);
}

/* Loading Styles */
.loadingContent {
  display: flex;
  align-items: center;
  padding: 14px;
  background-color: #f8f9fa;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
}

.loadingText {
  margin: 0;
  font-size: 13px;
  color: #5a6072;
  font-weight: 500;
}

/* Success Step Styles */
.appOnboardingSuccess {
  display: flex;
  flex-direction: column;
}

.appOnboardingTopContent {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  background-color: #f0f7ff;
  padding: 14px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(18, 82, 243, 0.06);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.appOnbaordingTopEmoji {
  font-size: 24px;
  margin: 0 14px 0 0;
}

.appOnboardingTopInfo {
  flex: 1;
}

.appOnboardingTopText {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #1252f3;
}

.appOnboardingTopDescription {
  font-size: 13px;
  margin: 0;
  color: #5a6072;
  line-height: 1.4;
}

.appOnboardingSuccessHeaderContent {
  text-align: center;
  margin-bottom: 18px;
}

.appOnboardingSuccessHeader {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: #191f2f;
}

.appOnboardingSuccessDescription {
  font-size: 13px;
  color: #5a6072;
  margin: 0;
  line-height: 1.4;
}

.appOnboardingSuccessContent {
  display: flex;
  flex-direction: column;
}

.appOnboardingSuccessHeaderIcon {
  font-size: 22px;
  color: #1252f3;
  margin-bottom: 12px;
}

.appOnboardingSuccessBadge {
  margin-bottom: 8px;
}

.appOnboardingClose {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
  color: #5a6072;
  cursor: pointer;
  transition: all 0.2s ease;
}

.appOnboardingClose:hover {
  color: #191f2f;
}

.appOnboardingSuccessDescriptionInput {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 16px;
  resize: vertical;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.appOnboardingSuccessDescriptionInput:focus {
  outline: none;
  border-color: #1252f3;
  box-shadow: 0 0 0 2px rgba(18, 82, 243, 0.08);
}

.appOnboardingSuccessButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.switch_button {
  display: flex;
  align-items: center;
  color: #1252f3;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.switch_button svg {
  margin-right: 4px;
}

.switch_button:hover {
  color: #0a3fd0;
}

.appOnboardingSuccessButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1252f3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.appOnboardingSuccessButton:hover {
  background-color: #0a3fd0;
}

.appOnboardingSuccessButton.loading {
  opacity: 0.8;
  cursor: not-allowed;
}

.appOnboardingSuccessButton p {
  margin: 0 0 0 6px;
}

/* Success Modal Styles */
.successModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.successModalContent {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  width: 460px;
  max-width: 90%;
  padding: 28px;
  text-align: center;
  position: relative;
  z-index: 2;
  animation: modalPop 0.3s ease-out;
}

@keyframes modalPop {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.successModalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1;
}

.successModalIcon {
  font-size: 42px;
  margin-bottom: 16px;
  color: #1252f3;
}

.successModalTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #191f2f;
}

.successModalDescription {
  font-size: 14px;
  color: #5a6072;
  margin: 0 0 22px 0;
  line-height: 1.5;
}

.successModalButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1252f3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(18, 82, 243, 0.15);
  margin: 0 auto;
}

.successModalButton:hover {
  background-color: #0a3fd0;
  box-shadow: 0 3px 8px rgba(18, 82, 243, 0.2);
}

/* Document Selection Styles */
.appOnboardingTags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.appOnboardingTag {
  font-size: 13px;
  color: #5a6072;
  background-color: #f5f5f7;
  padding: 8px 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0;
  font-weight: 500;
}

.appOnboardingTag:hover {
  background-color: #e9ecef;
  color: #191f2f;
}

.selectedAppOnboardingTag {
  background-color: #1252f3;
  color: white;
}

.selectedAppOnboardingTag:hover {
  background-color: #0a3fd0;
  color: white;
}

.appOnboardingDocuments {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 280px;
  overflow-y: auto;
  padding: 5px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  scrollbar-width: thin;
  scrollbar-color: #d1d5db transparent;
}

.appOnboardingDocuments::-webkit-scrollbar {
  width: 5px;
}

.appOnboardingDocuments::-webkit-scrollbar-track {
  background: transparent;
}

.appOnboardingDocuments::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 5px;
}

.documentSelected {
  max-height: 140px;
}

.appOnboardingInfoLabel {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 12px 0;
  color: #1252f3;
}

.appOnboardingEachDocument {
  display: flex;
  align-items: center;
  padding: 14px;
  background-color: #f8f9fa;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.appOnboardingEachDocument:hover {
  background-color: #f0f0f5;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.selectedAppOnboardingEachDocument {
  background-color: #e6f0ff;
  border: 1px solid #1252f3;
  box-shadow: 0 4px 10px rgba(18, 82, 243, 0.1);
}

.selectedAppOnboardingEachDocument:hover {
  background-color: #d9e8ff;
}

.appOnboardingEachDocumentIcon {
  font-size: 18px;
  color: #1252f3;
  margin-right: 10px;
}

.appOnboardingEachDocumentText {
  flex: 1;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #191f2f;
}

.documentCheckIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1252f3;
  transition: all 0.2s ease;
}

.documentCheckIconContainer:hover {
  background-color: #ff3b30;
  transform: rotate(90deg);
}

.documentCheckIcon {
  color: white;
  margin: 0;
  font-size: 14px;
}

.noDocumentsFound {
  text-align: center;
  padding: 25px;
  color: #5a6072;
  font-size: 14px;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-top: 8px;
}

.noDocumentsFound span {
  color: #1252f3;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

.documentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1252f3;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 14px 20px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(18, 82, 243, 0.2);
  margin-top: 12px;
}

.documentButton:hover {
  background-color: #0a3fd0;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(18, 82, 243, 0.3);
}

.documentButtonIcon {
  margin-right: 10px;
  font-size: 16px;
  animation: sparkle 1.5s ease infinite;
}

.documentButtonText {
  margin: 0;
}

.disabledDocumentButton {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabledDocumentButton:hover {
  background-color: #1252f3;
  transform: none;
  box-shadow: none;
}

/* Duplicate styles - removing */
.appOnboardingTags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.appOnboardingTag {
  font-size: 14px;
  color: #191f2f99;
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0;
}

.appOnboardingTag:hover {
  background-color: #e9ecef;
  color: #191f2f;
}

.selectedAppOnboardingTag {
  background-color: #1252f3;
  color: white;
}

.selectedAppOnboardingTag:hover {
  background-color: #0a3fd0;
  color: white;
}

.appOnboardingDocuments {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
  padding: 5px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.documentSelected {
  max-height: 150px;
}

.appOnboardingInfoLabel {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 10px 0;
  color: #1252f3;
}

.appOnboardingEachDocument {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.appOnboardingEachDocument:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

.selectedAppOnboardingEachDocument {
  background-color: #e6f0ff;
  border: 1px solid #1252f3;
}

.selectedAppOnboardingEachDocument:hover {
  background-color: #d9e8ff;
}

.appOnboardingEachDocumentIcon {
  font-size: 18px;
  color: #1252f3;
  margin-right: 10px;
}

.appOnboardingEachDocumentText {
  flex: 1;
  margin: 0;
  font-size: 14px;
  color: #191f2f;
}

.documentCheckIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1252f3;
  transition: all 0.2s ease;
}

.documentCheckIconContainer:hover {
  background-color: #ff3b30;
}

.documentCheckIcon {
  color: white;
  margin: 0;
  font-size: 14px;
}

.noDocumentsFound {
  text-align: center;
  padding: 20px;
  color: #191f2f99;
  font-size: 14px;
}

.noDocumentsFound span {
  color: #1252f3;
  cursor: pointer;
  text-decoration: underline;
}

.documentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1252f3;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.documentButton:hover {
  background-color: #0a3fd0;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(18, 82, 243, 0.2);
}

.documentButtonIcon {
  margin-right: 10px;
  font-size: 16px;
  animation: sparkle 1.5s ease infinite;
}

@keyframes sparkle {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
}

.documentButtonText {
  margin: 0;
}

.disabledDocumentButton {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabledDocumentButton:hover {
  background-color: #1252f3;
  transform: none;
  box-shadow: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .appOnboardingModalContent {
    width: 90%;
    max-height: 80vh;
  }

  .appOnboardingModalContentSuccess {
    width: 90%;
  }

  .appOnboardingContent {
    padding: 20px;
  }

  .appOnboardingHeaderText {
    font-size: 20px;
  }

  .appOnboardingHeaderDescription {
    font-size: 14px;
  }

  .appOnboardingMainButtons {
    flex-direction: column;
    align-items: stretch;
  }

  .appOnboardingBackButton {
    margin: 15px 0 0 0;
    text-align: center;
  }

  .appOnboardingTags {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-bottom: 10px;
  }

  .appOnboardingTag {
    white-space: nowrap;
  }
}
