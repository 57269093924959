.shareButton {
  /* background-color: #4775e7; */
  margin-right: 15px;
}

.shareButton:hover {
  background-color: #3a67d9;
}

.shareButton .saveButtonIcon {
  font-size: 14px;
}
