/* Create Document Modal Styles */
.createDocumentModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.createDocumentModalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.createDocumentModalContent {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  width: 650px;
  max-width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 2;
  position: relative;
  transition: all 0.3s ease;
}

.createDocumentModalHeader {
  padding: 24px 30px;
  border-bottom: 1px solid #f0f0f5;
  position: relative;
}

.createDocumentModalClose {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #5a6072;
  font-size: 18px;
  border: none;
}

.createDocumentModalClose:hover {
  background-color: #e9ecef;
  color: #191f2f;
  transform: rotate(90deg);
}

.createDocumentModalBadge {
  display: inline-block;
  padding: 4px 10px;
  background-color: rgba(0, 128, 0, 0.1);
  color: #008000;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
}

.createDocumentModalIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #f0f7ff;
  color: #1252f3;
  font-size: 20px;
  margin-right: 12px;
  margin-bottom: 16px;
}

.createDocumentModalTitle {
  font-size: 22px;
  font-weight: 700;
  color: #191f2f;
  margin: 0 0 10px 0;
}

.createDocumentModalDescription {
  font-size: 15px;
  color: #5a6072;
  margin: 0;
  line-height: 1.5;
}

.createDocumentModalBody {
  padding: 24px 30px;
}

.createDocumentTextarea {
  width: 100%;
  min-height: 180px;
  padding: 16px;
  border: 1px solid #e1e4e8;
  border-radius: 12px;
  font-size: 15px;
  margin: 20px 0;
  resize: vertical;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  font-family: inherit;
}

.createDocumentTextarea:focus {
  outline: none;
  border-color: #1252f3;
  box-shadow: 0 0 0 3px rgba(18, 82, 243, 0.1);
}

.createDocumentTextarea::placeholder {
  color: #a0a0a0;
}

.createDocumentModalFooter {
  padding: 20px 30px;
  border-top: 1px solid #f0f0f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createDocumentSwitchButton {
  display: flex;
  align-items: center;
  color: #1252f3;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #f0f7ff;
  border: none;
}

.createDocumentSwitchButton svg {
  margin-right: 8px;
  font-size: 16px;
}

.createDocumentSwitchButton:hover {
  background-color: #e6f0ff;
  color: #0a3fd0;
}

.createDocumentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1252f3;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(18, 82, 243, 0.2);
}

.createDocumentButton:hover {
  background-color: #0a3fd0;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(18, 82, 243, 0.3);
}

.createDocumentButton svg {
  margin-right: 8px;
  font-size: 16px;
}

.createDocumentButton.loading {
  opacity: 0.8;
  cursor: not-allowed;
}

.createDocumentButton.loading:hover {
  transform: none;
}

/* Document Selection Styles */
.documentSelectionHeader {
  margin-bottom: 20px;
  text-align: center;
}

.documentSearchInput {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e1e4e8;
  border-radius: 12px;
  font-size: 15px;
  margin-bottom: 16px;
  transition: all 0.2s ease;
}

.documentSearchInput:focus {
  outline: none;
  border-color: #1252f3;
  box-shadow: 0 0 0 3px rgba(18, 82, 243, 0.1);
}

.documentTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.documentTag {
  font-size: 13px;
  color: #5a6072;
  background-color: #f5f5f7;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0;
  font-weight: 500;
}

.documentTag:hover {
  background-color: #e9ecef;
  color: #191f2f;
}

.documentTagSelected {
  background-color: #1252f3;
  color: white;
}

.documentTagSelected:hover {
  background-color: #0a3fd0;
}

.documentList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 280px;
  overflow-y: auto;
  padding: 5px;
  margin-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: #d1d5db transparent;
}

.documentList::-webkit-scrollbar {
  width: 5px;
}

.documentList::-webkit-scrollbar-track {
  background: transparent;
}

.documentList::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 5px;
}

.documentItem {
  display: flex;
  align-items: center;
  padding: 14px;
  background-color: #f8f9fa;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.documentItem:hover {
  background-color: #f0f0f5;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.documentItemSelected {
  background-color: #e6f0ff;
  border: 1px solid #1252f3;
  box-shadow: 0 4px 10px rgba(18, 82, 243, 0.1);
}

.documentItemSelected:hover {
  background-color: #d9e8ff;
}

.documentItemIcon {
  font-size: 18px;
  color: #1252f3;
  margin-right: 12px;
}

.documentItemText {
  flex: 1;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #191f2f;
}

.documentItemRemove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1252f3;
  color: white;
  transition: all 0.2s ease;
}

.documentItemRemove:hover {
  background-color: #ff3b30;
  transform: rotate(90deg);
}

.noDocumentsFound {
  text-align: center;
  padding: 25px;
  color: #5a6072;
  font-size: 14px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.noDocumentsFound span {
  color: #1252f3;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

@keyframes sparkle {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
}

.sparkleIcon {
  animation: sparkle 1.5s ease infinite;
}
