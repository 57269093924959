:root {
  --primary-color: #1252f3;
  --primary-hover: #0e44d0;
  --primary-light: #e6eeff;
  --text-primary: #1a1f36;
  --text-secondary: #4f566b;
  --text-tertiary: #8792a2;
  --border-color: #e3e8ee;
  --background-light: #f7fafc;
  --background-white: #ffffff;
  --error-color: #df1b41;
  --success-color: #0ba95b;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.04);
  --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.08);
  --shadow-lg: 0 12px 24px rgba(0, 0, 0, 0.12);
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --transition-fast: 0.15s ease;
  --transition-normal: 0.25s ease;
}

.authModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26, 31, 54, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  padding: 10px;
}

.authModal {
  background-color: var(--background-white);
  border-radius: 20px;
  box-shadow: var(--shadow-lg);
  width: 100%;
  max-width: 500px;
  position: relative;
  overflow: hidden;
  animation: fadeIn 0.3s ease;
  padding: 0px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.authModalCloseButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  transition: all var(--transition-fast);
  z-index: 10;
}

.authModalCloseButton:hover {
  color: var(--text-secondary);
  background-color: var(--background-light);
}

.authModalHeader {
  padding: 25px 25px 15px;
  text-align: center;
  background-color: #1252f307;
}

.authModalLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.authModalLogoText {
  font-weight: 700;
  font-size: 18px;
  color: var(--text-primary);
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.authModalLogoIcon {
  width: 22px;
  height: 22px;
  background-color: var(--primary-color);
  border-radius: 6px;
}

.authModalTitle {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 10px;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.authModalDescription {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0 0 20px;
  line-height: 1.5;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.authModalTabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 16px;
  padding: 0 20px;
}

.authModalTab {
  flex: 1;
  background: transparent;
  border: none;
  padding: 10px 14px;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  position: relative;
}

.authModalTab.active {
  color: var(--primary-color);
  font-weight: 600;
}

.authModalTab.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--primary-color);
  border-radius: 2px 2px 0 0;
}

.authModalTab:hover:not(.active) {
  color: var(--text-primary);
}

.authModalContent {
  padding: 0 30px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  padding-top: 30px;
  /* margin-top: 10px; */
}

.authModalForm {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.authModalInputRow {
  display: flex;
  gap: 15px;
  width: 100%;
}

.authModalInputRow .authModalInputContainer {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

.authModalInputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.authModalInputLabel {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-primary);
  margin: 0;
}

.authModalInput {
  padding: 12px 14px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  font-size: 14px;
  transition: all var(--transition-fast);
  width: 100%;
  background-color: var(--background-light);
  color: var(--text-primary);
  box-shadow: var(--shadow-sm);
}

.authModalInput:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(18, 82, 243, 0.15);
}

.authModalInput::placeholder {
  color: var(--text-tertiary);
}

.authModalErrorText {
  color: var(--error-color);
  font-size: 14px;
  margin: 0;
  padding: 4px 0;
}

.authModalButton {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-lg);
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  height: 44px;
  box-shadow: 0 2px 4px rgba(18, 82, 243, 0.15);
}

.authModalButton:hover:not(:disabled) {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(18, 82, 243, 0.25);
}

.authModalButton:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(18, 82, 243, 0.2);
}

.authModalButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.authModalButtonText {
  margin: 0;
}

.authModalButtonIcon {
  font-size: 18px;
}

.authModalCheckIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.authModalCheckIcon {
  font-size: 18px;
  color: white;
}

/* Simple fade-in animation for form */
.authModalForm {
  animation: simpleFadeIn 0.2s ease;
}

@keyframes simpleFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Simple success animation */
@keyframes successFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.authModalSuccessIcon {
  animation: successFade 0.3s ease forwards;
}

/* Switch container styles */
.authModalSwitchContainer {
  margin-top: 20px;
  text-align: center;
}

.authModalSwitchText {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
}

.authModalSwitchButton {
  background: none;
  border: none;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  transition: all var(--transition-fast);
}

.authModalSwitchButton:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 480px) {
  .authModal {
    max-width: 100%;
    border-radius: var(--radius-md);
  }

  .authModalHeader {
    padding: 20px 20px 12px;
  }

  .authModalContent {
    padding: 0 20px 20px;
  }

  .authModalTabs {
    padding: 0 20px;
  }

  .authModalTitle {
    font-size: 18px;
  }

  .authModalDescription {
    font-size: 13px;
  }
}
