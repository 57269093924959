@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/violet.css";

/* reset */
/* button {
  all: unset;
} */

.TooltipContent {
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  color: #191f2f;
  background-color: white;
  box-shadow: 0 0px 20px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  user-select: none;
  animation-duration: 250ms; /* Reduced duration for quicker display */
  animation-timing-function: cubic-bezier(0.3, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 99999999999999999999999999999999999999999999999999 !important;
  max-width: 450px;
  line-height: 1.5em;
  word-wrap: break-word;
  white-space: pre-line;
}
.TooltipContent[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.TooltipArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  margin-top: 2px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #1252f3;
  letter-spacing: 0.5px;
}
.IconButton:hover {
  /* background-color: var(--violet3); */
}
.IconButton:focus {
  /* box-shadow: 0 0 0 2px black; */
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* reset */
button {
  all: unset;
}

.CheckboxRoot {
  background-color: white;
  width: 13px;
  height: 13px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 2px 10px var(--blackA7); */
  border: 1px solid #191f2f29;
  padding: 1px;
}
.CheckboxRoot:hover {
  background-color: var(--violet3);
  cursor: pointer;
}
.CheckboxRoot:focus {
  box-shadow: 0 0 0 2px black;
  font-weight: 500;
}

.CheckboxRoot[data-state="checked"] {
  background-color: #1252f3;
  color: white;
}

.CheckboxRoot[data-state="checked"] > .CheckboxIndicator {
  color: white;
}

.CheckboxIndicator {
  color: var(--violet11);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Label {
  color: #191f2f;
  padding-left: 7px !important;
  font-size: 13px;
  /* line-height: 1; */
  font-weight: 500;
  margin-bottom: 0px !important;
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  margin: 0px 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 0px;
}
