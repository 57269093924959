/* ShareDialog.css */
.ShareDialogOverlay {
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  inset: 0;
  animation: shareOverlayShow 200ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 99999;
  backdrop-filter: blur(2px);
}

.ShareDialogContent {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 15px 50px -10px rgba(22, 23, 24, 0.35),
    0 10px 20px -15px rgba(22, 23, 24, 0.2);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 0;
  animation: shareContentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100000;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.ShareDialogHeader {
  padding: 20px 24px 16px 24px;
  border-bottom: 1px solid #eaedf3;
  background: linear-gradient(to right, #f8faff, #f5f5f7);
}

/* Share Options Tabs */
.shareOptionsTabs {
  display: flex;
  border-bottom: 1px solid #eaedf3;
  background-color: #fafbfd;
}

.shareOptionTab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #5d667e;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.shareOptionTab svg {
  margin-right: 8px;
  font-size: 16px;
}

.shareOptionTab.active {
  color: #1252f3;
  background-color: #ffffff;
}

.shareOptionTab.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1252f3;
}

.shareOptionTab:hover:not(.active) {
  background-color: rgba(18, 82, 243, 0.05);
}

.ShareDialogIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(18, 82, 243, 0.1);
  color: #1252f3;
  font-size: 16px;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px rgba(18, 82, 243, 0.1);
}

.ShareDialogTitle {
  margin: 0;
  font-weight: 600;
  color: #191f2f;
  font-size: 18px;
}

.ShareDialogDescription {
  margin: 6px 0 0 0;
  color: #5d667e;
  font-size: 14px;
  line-height: 1.4;
}

.ShareDialogBody {
  padding: 20px 24px;
  overflow-y: auto;
  max-height: calc(85vh - 180px);
  background-color: #ffffff;
}

.ShareDialogFooter {
  padding: 16px 24px;
  border-top: 1px solid #eaedf3;
  display: flex;
  justify-content: flex-end;
  background-color: #fafbfd;
}

.ShareDialogCloseButton {
  font-family: inherit;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #5d667e;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  border: none;
  transition: all 0.2s ease;
  font-size: 16px;
}

.ShareDialogCloseButton:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: #191f2f;
  transform: rotate(90deg);
}

/* Share Step Styles */
.shareStep {
  animation: fadeIn 0.3s ease;
}

.shareStepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.shareStepIcon {
  font-size: 18px;
  color: #1252f3;
  margin-right: 10px;
}

.shareStepHeader h3 {
  font-size: 16px;
  font-weight: 600;
  color: #191f2f;
  margin: 0;
}

.shareStepDescription {
  font-size: 14px;
  color: #5d667e;
  margin-bottom: 14px;
}

/* Email Input Styles */
.emailInput {
  width: 100%;
  min-height: 80px;
  padding: 14px 16px;
  border: 1px solid #d4d6df;
  border-radius: 8px;
  font-size: 14px;
  resize: vertical;
  margin-bottom: 12px;
  font-family: inherit;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
}

.emailInput:focus {
  outline: none;
  border-color: #1252f3;
  box-shadow: 0 0 0 2px rgba(18, 82, 243, 0.1), 0 2px 5px rgba(0, 0, 0, 0.03);
}

.errorMessage {
  color: #e53935;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.errorMessage::before {
  content: "⚠️";
  margin-right: 6px;
  font-size: 14px;
}

.emailChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.emailChip {
  display: flex;
  align-items: center;
  background-color: rgba(90, 90, 110, 0.08);
  border: 1px solid rgba(90, 90, 110, 0.2);
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  color: #5a5a6e;
  animation: scaleIn 0.2s ease;
  box-shadow: 0 1px 2px rgba(90, 90, 110, 0.1);
}

.emailChipRemove {
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;
  color: #5d667e;
  transition: all 0.2s ease;
}

.emailChipRemove:hover {
  color: #e53935;
  transform: scale(1.2);
}

/* Link Styles */
.shareableLinkContainer {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.linkInput {
  flex: 1;
  padding: 12px 14px;
  border: 1px solid #d4d6df;
  border-radius: 8px;
  font-size: 14px;
  color: #191f2f;
  background-color: #f5f7fa;
  font-family: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

.linkHelpText {
  font-size: 13px;
  color: #5d667e;
  margin-top: 10px;
  text-align: center;
}

.linkLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px;
  background-color: #f5f7fa;
  border-radius: 8px;
  color: #5d667e;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

.linkLoading span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

/* Button Styles */
.shareButton,
.doneButton,
.copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  font-family: inherit;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shareButton {
  background-color: #1252f3;
  color: white;
  border-radius: 50px;
  box-shadow: none;
}

.shareButton:hover:not(.disabled) {
  background-color: #0e44d0;
  transform: translateY(-1px);
  outline: none;
  color: white;
  /* border: 1px solid #1252f3; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
}

.shareButton:active:not(.disabled) {
  transform: translateY(1px);
  outline: none;
  /* border: 1px solid #1252f3; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
}

.shareButton.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.doneButton {
  background-color: #5a5a6e;
  color: white;
}

.doneButton:hover {
  background-color: #4a4a5e;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.doneButton:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.copyButton {
  margin-left: 10px;
  background-color: #1252f310;
  color: #1252f3;
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: none;
}

.copyButton:hover:not(:disabled) {
  background-color: #1252f330;
  color: #1252f3;
  transform: translateY(-1px);
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); */
}

.copyButton:active:not(:disabled) {
  transform: translateY(1px);
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
}

.copyButton:disabled {
  background-color: #d4d6df;
  cursor: not-allowed;
  box-shadow: none;
}

.copyButton svg {
  margin-right: 6px;
  font-size: 16px;
}

.copyButton.copied {
  background-color: #4caf50;
  color: white;
}

/* Success Message Styles */
.successMessage {
  display: flex;
  align-items: center;
  background-color: #4caf5010;
  border: 1px solid #4caf5030;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 18px;
  animation: fadeIn 0.3s ease;
  box-shadow: 0 2px 6px rgba(76, 175, 80, 0.1);
}

.successIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  font-size: 14px;
  margin-right: 12px;
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.3);
}

.successMessage p {
  color: #2e7d32;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

/* Additional Animations */
.shake-animation {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  border-color: #e53935 !important;
}

.highlight-animation {
  animation: highlight 1s ease;
}

/* Animations */
@keyframes shareOverlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shareContentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}

@keyframes highlight {
  0% {
    background-color: #f5f7fa;
  }
  30% {
    background-color: rgba(18, 82, 243, 0.1);
  }
  100% {
    background-color: #f5f7fa;
  }
}
