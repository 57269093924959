/* StartupLocationInput.css */

.locationInputContainer {
  width: 100%;
  margin-bottom: 20px;
}

.locationInputLabel {
  font-size: 15px;
  color: #464b5a;
  margin-bottom: 7px;
  display: block;
}

.locationInputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.locationSelect {
  width: 100%;
  padding: 13px 15px;
  font-family: "Inter";
  border: 1px solid #d4d6df;
  background: transparent;
  border-radius: 7px;
  font-size: 14px;
  position: relative;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 12px auto;
}

.locationSelect:focus {
  outline: 3px solid #1252f33d;
  border-color: #1252f3;
}

.locationLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.locationError {
  color: #e53935;
  font-size: 14px;
  margin-top: 5px;
}

.locationHint {
  font-size: 12px;
  color: #8a8e99;
  margin-top: 5px;
}

/* Animation for dropdown appearance */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.stateSelect {
  animation: fadeInDown 0.3s ease-out;
}
